.convene {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    max-width:100vw;
    width: 100%;
    height: 100vh;
    padding: 3rem;
    background: white;
    overflow-y: hidden;
}
.roow {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
    max-width:100vw;
    width: 100%;
    padding: 3rem;
    padding-bottom: 0;
    background: white;
    overflow-y: hidden;
}
.textventa {
    display: grid;
    flex-direction: column;
    max-width:45%;
    flex-wrap:wrap;
    background-color: rgba(132, 0, 255, 0.1);
    border-radius: 1rem;
    padding: 1rem;
    margin: 0 1rem;
}


.titlecon {
    color: #FF7E20;
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 2.7rem;
    text-align:center;
}

.subtitlecon {
    font-family: var(--font-family);
    font-weight: 800;
    color: #7534b6;
    padding-bottom:1.5rem;
    text-align:center;
    justify-self: center;
}
.textcon {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 1.2rem;
    line-height: 2.5rem;
    color: #7534b6;
}
.black {
    color: black;
}
.mode {
    font-size: 1.3rem;
    text-align: center;
    background-color: rgba(132, 0, 255, 0.1);
    border-radius: 1rem;
    padding:1rem;

}

.imgsomos {
 width: 36%;
}
.imgsomos img {
 border-radius: 8rem 0 8rem 0;
 width: 100%;
 background-size: cover;
}

@media screen and (max-width: 1024px) {
    .convene{
        height: auto;
    }
    .roow{
        display: flex;
        flex-direction: column;
        padding: 0;
    }
    .textventa {
        width: auto;
        max-width: 90vw;
        margin: 1rem 0; 
    }
    .textcon {
        font-family: var(--font-family);
        font-weight: 800;
        font-size: 0.95rem;
        line-height: auto;
    }
    .subtitlecon {
        max-width: 90vw;
        margin-top:2rem;
    }
}

@media screen and (max-width: 850px) {
    .gpt3__whatgpt3-heading {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0rem;
    }

    .gpt3__whatgpt3-heading p {
        margin-top: 1rem;
    }
    .textcon {
        font-family: var(--font-family);
        font-weight: 800;
        font-size: 1.2rem;
        line-height: auto;
    }
}

@media screen and (max-width: 650px) {
    /* Customizing feature component as per needs */
    .gpt3__whatgpt3-feature .gpt3__features-container__feature {
        flex-direction: column;
    }

    /* Customizing feature component as per needs */
    .gpt3__whatgpt3-feature .gpt3__features-container_feature-text {
        margin-top: 0.5rem;
    }

    .gpt3__whatgpt3-heading h1 {
        font-size: 28px;
        line-height: 40px;
    }
    .titlecon{
        font-size: 30px;
    }
}

@media screen and (max-width: 350px) {
    /* Customizing feature component as per needs */
    .gpt3__whatgpt3-container .gpt3__features-container__feature {
        margin: 1rem 0;
        min-width: 100%;
    }
}

@media screen and (max-width: 425px) {
    /* Customizing feature component as per needs */
    .textcon {
        font-size: 15px;
    }
    .convene {
        padding: 3rem 1rem;
    }
}