.somosvenecia {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width:100vw;
    width: 100%;
    height: 90vh;
    padding: 1.4rem;
    padding-top: 2rem;
    background: white;
    overflow-y: hidden;
}
.roww {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width:100vw;
}

.textsomos {
    display: flex;
    flex-direction: column;
    padding: 3rem;
    width:55%;
}


.titlesomos {
    color: #FF7E20;
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 2.7rem;
    line-height: 25px;
}

.subtitlesomos {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 1.2rem;
    line-height: 2.5rem;
    color: #7534b6;
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.black {
    color: black;
}
.btnsomos {
    background-color: var(--purple);
    padding: 1.5rem 2rem;
    border-radius: 0.75rem;
    margin: 2rem 2rem;
    cursor: pointer;
    
    width: 70%;
    text-align: center;
    display:flex;
    font-weight: 800;
    font-family: var(--font-family);
    color: white;
    font-size: 1.8em;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.imgsomos {
 width: 36%;
}
.imgsomos img {
 border-radius: 8rem 0 8rem 0;
 width: 100%;
 background-size: cover;
}

@media screen and (max-width: 1440px) {
    .imgsomos {
        display: none;        
       }
    .textsomos {
    width: auto;
    padding: 2rem;
    padding-top: 0;
    text-align: center;
    }
    .btnsomos {
    align-self: center;
    font-size: large;
    }
    .somosvenecia {
    height: auto;
    }
    .titlesomos {
        margin-top: 2rem;
    }
    
}

@media screen and (max-width: 850px) {
    .gpt3__whatgpt3-heading {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0rem;
    }
    .subtitlesomos {
        font-size: 0.95rem;
        line-height: 2rem;
    }
    .textsomos {
        width: auto;
        padding: 1rem;
        padding-top: 0;
        text-align: center;
        }

    .gpt3__whatgpt3-heading p {
        margin-top: 1rem;
    }
    .btnsomos{
    padding: 1rem !important;
    margin: 1rem !important;
    
    width: 100% !important;
    text-align: center;
    font-size: 25px !important;
    
    }
}

@media screen and (max-width: 650px) {
    /* Customizing feature component as per needs */
    .gpt3__whatgpt3-feature .gpt3__features-container__feature {
        flex-direction: column;
    }

    /* Customizing feature component as per needs */
    .gpt3__whatgpt3-feature .gpt3__features-container_feature-text {
        margin-top: 0.5rem;
    }

    .gpt3__whatgpt3-heading h1 {
        font-size: 28px;
        line-height: 40px;
    }
    .titlesomos {
        font-size: 30px;
        line-height: auto;
        padding-top:2rem;
    }
}

@media screen and (max-width: 350px) {
    /* Customizing feature component as per needs */
    .gpt3__whatgpt3-container .gpt3__features-container__feature {
        margin: 1rem 0;
        min-width: 100%;
    }
}