.slider {
    margin: auto;
    padding: 1rem;
    padding-top: 4rem;
    padding-bottom:0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center !important;
    width: 100vw;
    background-color: var(--white);
  }
.titleeco {
    color: #FF7E20;
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 2.7rem;
    line-height: 25px;
    padding-top:3rem;
}
  .carousel-indicators {
    margin-left: 46% !important;
  }

  .card-group {
    height: auto !important;
    width: 80vw !important;
    padding-top:2rem;
  }
  .card {
    margin:3rem;
  }

   .card-img-top {
    min-height:40%;
    max-height:40%;
    object-fit: cover;
  }
  .card-text {
    flex: 0 !important;
    text-align: center !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 16px;
  }
  .flex-roww {
    display: flex;
    flex-direction:row;
    text-align:center;
  }
  .card-body {
    text-align: center;
    margin: 0 auto;
    display: flex;
    flex: 0 !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 500;
  }
  .roowcaru {
    display: flex;
    flex-direction: row;
    padding-top: 4rem;
    justify-content: center;
  }
  .last-row {
    display: flex;
    text-align:right;
  }
  .ima {
    width: 50px;
    height: 50px;
    margin: 0rem 4rem;
  }

  @media screen and (max-width: 1440px) {
    .slider {
      margin: inherit;
    }
    .card-group {
      width:100% !important;
    }
    .card {
      margin:1rem;
    }
    .roowcaru {
      width:auto;
    }
    .ima {
      margin: 1rem 1rem;
    }
    .carousel {
      width: 100vw !important;
    }
}
@media screen and (max-width: 850px) {
    .card{
      margin:1.5rem !important;
    }
    .card-text {
      line-height: 20px;
    }
    .card-group {
      flex-direction: column !important;
      align-items: center !important;
    }
    .titleeco {
      font-size: 30px;
    }
    .carousel-indicators{
      margin-left: 40% !important;
    }
    .ima{
      width: 30px;
    height: 30px;
    }
}