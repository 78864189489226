.gpt3__navbar {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 100vw;
    width: 100vw;
    background-color: var(--white);
    padding: 0rem 3rem;
}

.gpt3__navbar-links {
    max-width: 95vw;
    display: flex;
    justify-content: space-around;
    /* align-items: flex-start; */
}

.gpt3__navbar-links_logo {
    margin-right: 2rem;
    padding-top: 0rem;
}

.gpt3__navbar-links_logo img {
    width: 480px;
    height: 210px;
}

.gpt3__navbar-links_container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 60%;
}

.orangeflag {
    padding: 2.5rem 1rem;
    /* border-radius: 0 0 3rem 3rem; */
    /* margin: 0 1rem; */
    /* cursor: pointer; */
    
    text-align: center;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.orangeflag a {
    text-decoration: none;
}

.orangeflag h2{
    line-height:1.5rem;
    color: var(--purple);
    font-family: var(--font-family);
    font-weight: 800;
}
.orangeflag h3{
    line-height:1.5rem;
    color: var(--purple);
    font-family: var(--font-family);
    font-weight: 800;
    font-size:1rem;
}


.langdiv{
    border-radius: 30px;
    width: 40px;
    height: 25px;
    margin: 5px;
    cursor: pointer;
}
.langdiv img{
    width: 100%;
    height: 100%;
}

.langcontainer {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    padding-top: 2rem;
}

.gpt3__navbar-links_container p,
.gpt3__navbar-sign p,
.gpt3__navbar-menu_container p {
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;

    margin: 0 1rem;
    cursor: pointer;
}

.gpt3__navbar-sign button,
.gpt3__navbar-menu_container button {
    padding: 0.5rem 1rem;
    color: #fff;
    background: #FF4820;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
}

.gpt3__navbar-menu {
    margin-left: 1rem;

    display: none;
    position: relative;
}

.gpt3__navbar-menu svg {
    cursor: pointer;
}

.gpt3__navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    
    text-align: end;
    background: var(--color-footer);
    padding: 2rem;
    position: absolute;
    right: 0;
    top: 40px;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0,0, 0,0.2);
}

.gpt3__navbar-menu_container p {
    margin: 1rem 0;
}

.gpt3__navbar-menu_container-links-sign {
    display: none;
}
@media screen and (max-width: 1400px) {
    .gpt3__navbar {
        padding: 0 2rem;
    }
    .gpt3__navbar-links_logo img{
        width: 320px;
        height:140px;
    }
    .gpt3__navbar-links_container {
        width: 90%;
    }
    .orangeflag h3 {
        font-size: 0.8rem;
    }
}

@media screen and (min-width: 850px) {
    .gpt3__navbar-links_container{
        margin-left:5rem;
    }
}

@media screen and (max-width: 1050px) {
    .gpt3__navbar-links_container {
        /* display: none; */
    }

    .gpt3__navbar {
       padding: 0;
    }
    .gpt3__navbar-menu {
        display: flex;
    }
    .gpt3__navbar-links_logo img{
        width: 200px;
        height:100px;
    }
    .gpt3__navbar-links_logo{
        margin-right: 0;
        margin-left:1rem;
    }
    .gpt3__navbar-links_container{
        width: auto;
    }

}

@media screen and (min-width: 851px) and  (max-width: 1390px){
    .langcontainer {
        padding-right: 3rem;
    } 
}

@media screen and (max-width: 850px) {
    .gpt3__navbar-links_container {
        display: flex;
        flex-direction: column;

    }
    .orangeflag{
        padding:1rem;
    }
    .gpt3__navbar {
        display: flex;
        flex-direction: column;
        min-width:100vw;
        align-items:center;
    }
    .gpt3__navbar-links {
        display: flex;
        flex-direction: column;
        justify-content:center;
        align-items:center;
    }
    .gpt3__navbar-links_container {
        align-items: center;
    }

    .langcontainer {
        flex-direction: row;
        padding-top: 0;
        padding-bottom: 2rem;
    }
}

@media screen and (min-width: 701px) {
    .gpt3__navbar-links_logo img{
        padding-left: 2rem;
        padding-top: 1rem;
        object-fit: contain;
    }
}

@media screen and (max-width: 700px) {
    .gpt3__navbar {
        padding: 2rem 4rem;
    }
    .gpt3__navbar-links_logo img {
        width: 280px;
        height: 140px;
    }
    .langcontainer {
        /* padding-right: 3rem; */
    }
    

}
@media screen and (min-width: 768px) and (max-width: 1040px) {
    .langcontainer {
        /* padding-right: 5rem; */
    }
}


@media screen and (max-width: 550px) {
    .gpt3__navbar {
        padding: 2rem;
        align-items: center;
        display: flex;
    }

    .gpt3__navbar-sign {
        display: none;
    }

    .gpt3__navbar-menu_container {
        top: 20px;
    }

    .gpt3__navbar-menu_container-links-sign {
        display: block;
    }
}
