.app__footer {
  flex: 1;
  width: 100vw;
  flex-direction: column;
  display:flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: var(--white);
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}
.topfooter{
  flex: 1;
  width: 100%;
  flex-direction: row;
  display:flex;
  padding:4rem;
  justify-content: space-evenly;
  align-items: center;
}
.app__footer-cards {
  width: 80%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  margin: 4em 2rem 2rem;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.app__footer-card {
  min-width: 390px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  margin: 1rem 0;
  padding: 1rem;
  border-radius: 10px;
  cursor: pointer;
  background-color: var(--purple);

  transition: all 0.3s ease-in-out;

  &:hover {
    box-shadow: 0 0 25px #ffffff;
  }

  @media screen and (max-width: 450px) {
    width: 100%;
  }
}
.app__footer-card img {
  width: 40px;
  height: 40px;
  margin: 0 0.7rem;
}

.app__footer-card p {
  font-weight: 500;
}
.app__footer-card a {
  text-decoration: none;
  font-weight: 500;
}

.app__footer-cards .app__footer-card:last-child {
  background-color: #ffffff;
}

.app__footer-cards .app__footer-card:last-child:hover {
  box-shadow: 0 0 25px #ffffff;
}

.app__footer-form {
  width: 100%;
  flex-direction: column;
  margin: 1rem 2rem;

    &:hover {
      box-shadow: 0 0 25px white;
    }
  }

.app__footer-form div {
    width: 100%;

    margin: 0.75rem 0;
    border-radius: 10px;
    cursor: pointer;
    background-color: var(--white);

    transition: all 0.3s ease-in-out;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 1rem 0;
  }
}

.flex-column {
  display: flex;
  flex-direction: column;
  justify-conten: center;
  align-items: center;
  width: 25%;
}

.img-legal img {
  width: 40px;
  height: 40px;
}

.app__footer-form input, textarea {
      width: 100%;
      padding: 0.9rem;
      border: none;
      border-radius: 7px;
      background-color: #e5e5e5;

      font-family: var(--font-base);
      color: var(--pink);
      outline: none;
    }

    textarea {
      height: 170px;
    }

.app__footer-form form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.app__footer-form button, #submit {
  width: 50%;
  align-self: center;
  text-align: center;
  padding: 1rem 2rem;
  border-radius: 10px;
  border: none;
  background-color: var(--orange);
  font-weight: 500;
  color: var(--white-color);
  outline: none;
  margin: 2rem 0 0 0;
  font-family: var(--font-base);

  transition: cubic-bezier(0.55, 0.085, 0.68, 0.53);
  cursor: pointer;

  &:hover {
    background-color: #2430af;
  }
}
.leftfooter{
  display:flex;
  width:700px;
}
.rightcontact {
  display:flex;
  flex-direction: column;
  padding:1rem;
  width:40%;
}
.rightcontact h1{
    color: var(--orange);
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 2.7rem;
    line-height: 25px;
    margin-bottom:2rem;
}
.bottomfooter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding:4rem;
}
.bottomfooter h1 {
  color: var(--orange);
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 2.7rem;
    margin-bottom:2rem;
    text-align: center;
}
.avisolegal h2 {
  text-align: center;
}
h2 {
  color: var(--purple);
}
.bottomfooter img {
  width:100px;
  margin:2rem;
}
.rightcontact h3{
    color: var(--purple);
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 2rem;
    line-height: 25px;
    margin-top:2rem;
    margin-bottom:1rem;
}
.avisolegal {
  display:flex;
  flex-direction: row;
  justify-content: space-evenly;
  width:100%;
  background-color: rgba(132, 0, 255, 0.1);
  padding: 1rem;
  padding-top: 2rem;
}
.avisolegal h2 {
    color: var(--orange);
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 25px;
    margin-bottom:1.5rem;
    padding-top:1.75rem;
}
.ruw{
  display:flex;
  flex-direction:row;
  justify-content: space-around;
}
.riw{
  display:flex;
  flex-direction:row;
  justify-content: space-around;
}
.lbl{
  olor: var(--orange);
  t-family: var(--font-family);
  padding-left:0.8rem;
  font-size: 1.2rem;
}

@media screen and (max-width: 1024px) {
  .topfooter {
    display:flex;
    flex-direction: column-reverse;
  }
  .leftfooter{
    width:auto;
    min-width: 70vw;
  }
  .rightcontact{
    width: auto;
    display: flex;
    align-items: center;
  }
  .rightcontact div{
    width: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .rightcontact h4{
    font-size: medium;
  }
  .app__footer-cards {
    display: none;
  }
  #submit{
    width: auto;
  }
  .avisolegal h2 {
    font-size: medium;
    line-height: auto;
  }
  .bottomfooter h2 {
    text-align:center;
  }
}

@media screen and (min-width: 600px) and (max-width: 1186px){
  .redes {
    display: flex;
    align-items: center;
    margin: 2rem 0rem;
  }
  .bottomfooter img {
    width: 80px;
  }
}

@media screen and (max-width: 650px) {
  .bottomfooter h1 {
    font-size: 30px;
    text-align: center;
  }
  .rightcontact h1 {
    font-size: 30px;
    text-align: center;
  }
  .rightcontact h3 {
    font-size: 25px;
  }
  .bottomfooter div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction:column;
  }
  .bottomfooter img {
    margin:1.5rem;
    width:60px;
  }
  .redes {
    margin-bottom:3rem;

  }
  .riw{
    display:flex;
    flex-direction:column;
    justify-content: space-around;
  }
}
