.regis {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background-color: #FF7E20;
    padding: 3rem 6rem;
    color: white;
}

.regisdiv {
    display: flex;
    justify-content: center;
    align-self: center;
    text-align: center;
    width: 70%;
}
.regisdiv h1 {
    font-size: 30px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
}

.regiscardtitle h1 {
    color: var(--color-subtext);
    font-size: 16px;
    line-height: 30px;
    font-weight: 500;
    font-family: var(--font-family);
    text-align: center;
    margin-top: 2rem;
}
.regiscards {
    height: 80%;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
}
.regiscard {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 30%;
    height: 90%;
    background-color: white;
    border-radius: 1rem;

}
.regiscardimgdiv {
    width:100%;
    max-width:100%;
    height:80%;
    border-radius: 1rem;
}
.regiscardimg {
    width:100%;
    max-width:100%;
    height: -webkit-fill-available;
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    object-fit: cover;
}
.regiscardtitle h1{
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 30px;
    line-height: 55px;
    color: var(--purple);
}
@media screen and (max-width: 1440px) {
    .regiscardtitle h1{
        font-family: var(--font-family);
        font-weight: 700;
        font-size: 24px;
        color: var(--purple);
    }
    .regis {
        padding: 1rem 2rem;
        height: auto;
    }
    .regiscards {
        flex-direction: column;
    }
    .regiscard {
        width: 50%;
        margin: 2rem 0rem;
    }
    .regisdiv {
        /* width: auto; */
    }
    .regisdiv h1 {
        font-size: 20px;
    }
}

@media screen and (max-width: 990px) {
    .gpt3__features {
        flex-direction: column;
    }

    .gpt3__features-heading {
        margin: 0 0 2rem 0;
    }
    .regiscard {
        width: 80%;
    }
}


@media screen and (max-width: 550px) {
    .gpt3__features-heading h1 {
        font-size: 28px;
        line-height: 38px;
    }
}