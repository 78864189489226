@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap);
.regis {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background-color: #FF7E20;
    padding: 3rem 6rem;
    color: white;
}

.regisdiv {
    display: flex;
    justify-content: center;
    align-self: center;
    text-align: center;
    width: 70%;
}
.regisdiv h1 {
    font-size: 30px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
}

.regiscardtitle h1 {
    color: var(--color-subtext);
    font-size: 16px;
    line-height: 30px;
    font-weight: 500;
    font-family: var(--font-family);
    text-align: center;
    margin-top: 2rem;
}
.regiscards {
    height: 80%;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
}
.regiscard {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 30%;
    height: 90%;
    background-color: white;
    border-radius: 1rem;

}
.regiscardimgdiv {
    width:100%;
    max-width:100%;
    height:80%;
    border-radius: 1rem;
}
.regiscardimg {
    width:100%;
    max-width:100%;
    height: -webkit-fill-available;
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    object-fit: cover;
}
.regiscardtitle h1{
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 30px;
    line-height: 55px;
    color: var(--purple);
}
@media screen and (max-width: 1440px) {
    .regiscardtitle h1{
        font-family: var(--font-family);
        font-weight: 700;
        font-size: 24px;
        color: var(--purple);
    }
    .regis {
        padding: 1rem 2rem;
        height: auto;
    }
    .regiscards {
        flex-direction: column;
    }
    .regiscard {
        width: 50%;
        margin: 2rem 0rem;
    }
    .regisdiv {
        /* width: auto; */
    }
    .regisdiv h1 {
        font-size: 20px;
    }
}

@media screen and (max-width: 990px) {
    .gpt3__features {
        flex-direction: column;
    }

    .gpt3__features-heading {
        margin: 0 0 2rem 0;
    }
    .regiscard {
        width: 80%;
    }
}


@media screen and (max-width: 550px) {
    .gpt3__features-heading h1 {
        font-size: 28px;
        line-height: 38px;
    }
}
.app__footer {
  flex: 1 1;
  width: 100vw;
  flex-direction: column;
  display:flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: var(--white);
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}
.topfooter{
  flex: 1 1;
  width: 100%;
  flex-direction: row;
  display:flex;
  padding:4rem;
  justify-content: space-evenly;
  align-items: center;
}
.app__footer-cards {
  width: 80%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  margin: 4em 2rem 2rem;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.app__footer-card {
  min-width: 390px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  margin: 1rem 0;
  padding: 1rem;
  border-radius: 10px;
  cursor: pointer;
  background-color: var(--purple);

  transition: all 0.3s ease-in-out;

  &:hover {
    box-shadow: 0 0 25px #ffffff;
  }

  @media screen and (max-width: 450px) {
    width: 100%;
  }
}
.app__footer-card img {
  width: 40px;
  height: 40px;
  margin: 0 0.7rem;
}

.app__footer-card p {
  font-weight: 500;
}
.app__footer-card a {
  text-decoration: none;
  font-weight: 500;
}

.app__footer-cards .app__footer-card:last-child {
  background-color: #ffffff;
}

.app__footer-cards .app__footer-card:last-child:hover {
  box-shadow: 0 0 25px #ffffff;
}

.app__footer-form {
  width: 100%;
  flex-direction: column;
  margin: 1rem 2rem;

    &:hover {
      box-shadow: 0 0 25px white;
    }
  }

.app__footer-form div {
    width: 100%;

    margin: 0.75rem 0;
    border-radius: 10px;
    cursor: pointer;
    background-color: var(--white);

    transition: all 0.3s ease-in-out;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 1rem 0;
  }
}

.flex-column {
  display: flex;
  flex-direction: column;
  justify-conten: center;
  align-items: center;
  width: 25%;
}

.img-legal img {
  width: 40px;
  height: 40px;
}

.app__footer-form input, textarea {
      width: 100%;
      padding: 0.9rem;
      border: none;
      border-radius: 7px;
      background-color: #e5e5e5;

      font-family: var(--font-base);
      color: var(--pink);
      outline: none;
    }

    textarea {
      height: 170px;
    }

.app__footer-form form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.app__footer-form button, #submit {
  width: 50%;
  align-self: center;
  text-align: center;
  padding: 1rem 2rem;
  border-radius: 10px;
  border: none;
  background-color: var(--orange);
  font-weight: 500;
  color: var(--white-color);
  outline: none;
  margin: 2rem 0 0 0;
  font-family: var(--font-base);

  transition: cubic-bezier(0.55, 0.085, 0.68, 0.53);
  cursor: pointer;

  &:hover {
    background-color: #2430af;
  }
}
.leftfooter{
  display:flex;
  width:700px;
}
.rightcontact {
  display:flex;
  flex-direction: column;
  padding:1rem;
  width:40%;
}
.rightcontact h1{
    color: var(--orange);
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 2.7rem;
    line-height: 25px;
    margin-bottom:2rem;
}
.bottomfooter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding:4rem;
}
.bottomfooter h1 {
  color: var(--orange);
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 2.7rem;
    margin-bottom:2rem;
    text-align: center;
}
.avisolegal h2 {
  text-align: center;
}
h2 {
  color: var(--purple);
}
.bottomfooter img {
  width:100px;
  margin:2rem;
}
.rightcontact h3{
    color: var(--purple);
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 2rem;
    line-height: 25px;
    margin-top:2rem;
    margin-bottom:1rem;
}
.avisolegal {
  display:flex;
  flex-direction: row;
  justify-content: space-evenly;
  width:100%;
  background-color: rgba(132, 0, 255, 0.1);
  padding: 1rem;
  padding-top: 2rem;
}
.avisolegal h2 {
    color: var(--orange);
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 25px;
    margin-bottom:1.5rem;
    padding-top:1.75rem;
}
.ruw{
  display:flex;
  flex-direction:row;
  justify-content: space-around;
}
.riw{
  display:flex;
  flex-direction:row;
  justify-content: space-around;
}
.lbl{
  olor: var(--orange);
  t-family: var(--font-family);
  padding-left:0.8rem;
  font-size: 1.2rem;
}

@media screen and (max-width: 1024px) {
  .topfooter {
    display:flex;
    flex-direction: column-reverse;
  }
  .leftfooter{
    width:auto;
    min-width: 70vw;
  }
  .rightcontact{
    width: auto;
    display: flex;
    align-items: center;
  }
  .rightcontact div{
    width: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .rightcontact h4{
    font-size: medium;
  }
  .app__footer-cards {
    display: none;
  }
  #submit{
    width: auto;
  }
  .avisolegal h2 {
    font-size: medium;
    line-height: auto;
  }
  .bottomfooter h2 {
    text-align:center;
  }
}

@media screen and (min-width: 600px) and (max-width: 1186px){
  .redes {
    display: flex;
    align-items: center;
    margin: 2rem 0rem;
  }
  .bottomfooter img {
    width: 80px;
  }
}

@media screen and (max-width: 650px) {
  .bottomfooter h1 {
    font-size: 30px;
    text-align: center;
  }
  .rightcontact h1 {
    font-size: 30px;
    text-align: center;
  }
  .rightcontact h3 {
    font-size: 25px;
  }
  .bottomfooter div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction:column;
  }
  .bottomfooter img {
    margin:1.5rem;
    width:60px;
  }
  .redes {
    margin-bottom:3rem;

  }
  .riw{
    display:flex;
    flex-direction:column;
    justify-content: space-around;
  }
}

.gpt3__header {
    display: flex;
    flex-direction: row;
    background-repeat: no-repeat;
    height:100%;

}

.gpt3__header-content {
    flex: 1 1;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: column;
    height:100%;
    width:100vw;
}

.purplebox {
    max-width: 90vw;
    color: white;
    background-color: var(--purple);
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction:column;
    letter-spacing:1px;
    padding:1rem;
    padding-left:6rem;
    padding-right:6rem;
    border-radius: 0 2rem 2rem 0;
    margin-bottom:1rem;
}

.gpt3__header-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 30px;
    line-height: 75px;
}

.gpt3__header-content p {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: var(--color-text);

    margin-top: 1.5rem;
}

.gpt3__header-content__input {
    width: 100%;
    margin: 2rem 0 1rem;

    display: flex;
    flex-direction: row;
}

.gpt3__header-content__input input {
    flex: 2 1;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: var(--color-footer);
    border: 2px solid var(--color-footer);
    padding: 0 1rem;
    outline: none;
    color: #fff;

    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.gpt3__header-content__input button {
    flex: 0.6 1;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: #FF4820;
    border: 2px solid #FF4820;
    padding: 0 1rem;
    color: #fff;
    cursor: pointer;
    outline: none;

    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.gpt3__header-content__people {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;

    margin-top: 2rem;
}



.gpt3__header-content__people p {
    margin: 0 0 0 1rem;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 12px;
    line-height: 38px;
    color: #fff;
    text-align: center;
}

.gpt3__header-image {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.gpt3__header-image img {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 1400px) {
    .gpt3__header {
    }

    .gpt3__header-content {
        margin: 0 0 3rem;
    }
    .purplebox {
       
      }
}

@media screen and (max-width: 650px) {
    .gpt3__header-content h1 {
        font-size: 48px;
        line-height: 60px;
    }

    .gpt3__header-content p {
        font-size: 16px;
        line-height: 24px;
    }
    
    .gpt3__header-content__people {
        flex-direction: column;
    }

    .gpt3__header-content__people p {
        margin: 0;
    }

    .gpt3__header-content__input input,
    .gpt3__header-content__input button {
        font-size: 16px;
        line-height: 24px;
    }
}

@media screen and (max-width: 490px) {
    .gpt3__header-content h1 {
        font-size: 26px;
        line-height: 48px;
    }

    .gpt3__header-content p {
        font-size: 14px;
        line-height: 24px;
    }

    .gpt3__header-content__input input,
    .gpt3__header-content__input button {
        font-size: 12px;
        line-height: 16px;
    }
}
.somosvenecia {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width:100vw;
    width: 100%;
    height: 90vh;
    padding: 1.4rem;
    padding-top: 2rem;
    background: white;
    overflow-y: hidden;
}
.roww {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width:100vw;
}

.textsomos {
    display: flex;
    flex-direction: column;
    padding: 3rem;
    width:55%;
}


.titlesomos {
    color: #FF7E20;
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 2.7rem;
    line-height: 25px;
}

.subtitlesomos {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 1.2rem;
    line-height: 2.5rem;
    color: #7534b6;
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.black {
    color: black;
}
.btnsomos {
    background-color: var(--purple);
    padding: 1.5rem 2rem;
    border-radius: 0.75rem;
    margin: 2rem 2rem;
    cursor: pointer;
    
    width: 70%;
    text-align: center;
    display:flex;
    font-weight: 800;
    font-family: var(--font-family);
    color: white;
    font-size: 1.8em;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.imgsomos {
 width: 36%;
}
.imgsomos img {
 border-radius: 8rem 0 8rem 0;
 width: 100%;
 background-size: cover;
}

@media screen and (max-width: 1440px) {
    .imgsomos {
        display: none;        
       }
    .textsomos {
    width: auto;
    padding: 2rem;
    padding-top: 0;
    text-align: center;
    }
    .btnsomos {
    align-self: center;
    font-size: large;
    }
    .somosvenecia {
    height: auto;
    }
    .titlesomos {
        margin-top: 2rem;
    }
    
}

@media screen and (max-width: 850px) {
    .gpt3__whatgpt3-heading {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0rem;
    }
    .subtitlesomos {
        font-size: 0.95rem;
        line-height: 2rem;
    }
    .textsomos {
        width: auto;
        padding: 1rem;
        padding-top: 0;
        text-align: center;
        }

    .gpt3__whatgpt3-heading p {
        margin-top: 1rem;
    }
    .btnsomos{
    padding: 1rem !important;
    margin: 1rem !important;
    
    width: 100% !important;
    text-align: center;
    font-size: 25px !important;
    
    }
}

@media screen and (max-width: 650px) {
    /* Customizing feature component as per needs */
    .gpt3__whatgpt3-feature .gpt3__features-container__feature {
        flex-direction: column;
    }

    /* Customizing feature component as per needs */
    .gpt3__whatgpt3-feature .gpt3__features-container_feature-text {
        margin-top: 0.5rem;
    }

    .gpt3__whatgpt3-heading h1 {
        font-size: 28px;
        line-height: 40px;
    }
    .titlesomos {
        font-size: 30px;
        line-height: auto;
        padding-top:2rem;
    }
}

@media screen and (max-width: 350px) {
    /* Customizing feature component as per needs */
    .gpt3__whatgpt3-container .gpt3__features-container__feature {
        margin: 1rem 0;
        min-width: 100%;
    }
}
.convene {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    max-width:100vw;
    width: 100%;
    height: 100vh;
    padding: 3rem;
    background: white;
    overflow-y: hidden;
}
.roow {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
    max-width:100vw;
    width: 100%;
    padding: 3rem;
    padding-bottom: 0;
    background: white;
    overflow-y: hidden;
}
.textventa {
    display: grid;
    flex-direction: column;
    max-width:45%;
    flex-wrap:wrap;
    background-color: rgba(132, 0, 255, 0.1);
    border-radius: 1rem;
    padding: 1rem;
    margin: 0 1rem;
}


.titlecon {
    color: #FF7E20;
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 2.7rem;
    text-align:center;
}

.subtitlecon {
    font-family: var(--font-family);
    font-weight: 800;
    color: #7534b6;
    padding-bottom:1.5rem;
    text-align:center;
    justify-self: center;
}
.textcon {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 1.2rem;
    line-height: 2.5rem;
    color: #7534b6;
}
.black {
    color: black;
}
.mode {
    font-size: 1.3rem;
    text-align: center;
    background-color: rgba(132, 0, 255, 0.1);
    border-radius: 1rem;
    padding:1rem;

}

.imgsomos {
 width: 36%;
}
.imgsomos img {
 border-radius: 8rem 0 8rem 0;
 width: 100%;
 background-size: cover;
}

@media screen and (max-width: 1024px) {
    .convene{
        height: auto;
    }
    .roow{
        display: flex;
        flex-direction: column;
        padding: 0;
    }
    .textventa {
        width: auto;
        max-width: 90vw;
        margin: 1rem 0; 
    }
    .textcon {
        font-family: var(--font-family);
        font-weight: 800;
        font-size: 0.95rem;
        line-height: auto;
    }
    .subtitlecon {
        max-width: 90vw;
        margin-top:2rem;
    }
}

@media screen and (max-width: 850px) {
    .gpt3__whatgpt3-heading {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0rem;
    }

    .gpt3__whatgpt3-heading p {
        margin-top: 1rem;
    }
    .textcon {
        font-family: var(--font-family);
        font-weight: 800;
        font-size: 1.2rem;
        line-height: auto;
    }
}

@media screen and (max-width: 650px) {
    /* Customizing feature component as per needs */
    .gpt3__whatgpt3-feature .gpt3__features-container__feature {
        flex-direction: column;
    }

    /* Customizing feature component as per needs */
    .gpt3__whatgpt3-feature .gpt3__features-container_feature-text {
        margin-top: 0.5rem;
    }

    .gpt3__whatgpt3-heading h1 {
        font-size: 28px;
        line-height: 40px;
    }
    .titlecon{
        font-size: 30px;
    }
}

@media screen and (max-width: 350px) {
    /* Customizing feature component as per needs */
    .gpt3__whatgpt3-container .gpt3__features-container__feature {
        margin: 1rem 0;
        min-width: 100%;
    }
}

@media screen and (max-width: 425px) {
    /* Customizing feature component as per needs */
    .textcon {
        font-size: 15px;
    }
    .convene {
        padding: 3rem 1rem;
    }
}
.slider {
    margin: auto;
    padding: 1rem;
    padding-top: 4rem;
    padding-bottom:0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center !important;
    width: 100vw;
    background-color: var(--white);
  }
.titleeco {
    color: #FF7E20;
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 2.7rem;
    line-height: 25px;
    padding-top:3rem;
}
  .carousel-indicators {
    margin-left: 46% !important;
  }

  .card-group {
    height: auto !important;
    width: 80vw !important;
    padding-top:2rem;
  }
  .card {
    margin:3rem;
  }

   .card-img-top {
    min-height:40%;
    max-height:40%;
    object-fit: cover;
  }
  .card-text {
    flex: 0 1 !important;
    text-align: center !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 16px;
  }
  .flex-roww {
    display: flex;
    flex-direction:row;
    text-align:center;
  }
  .card-body {
    text-align: center;
    margin: 0 auto;
    display: flex;
    flex: 0 1 !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 500;
  }
  .roowcaru {
    display: flex;
    flex-direction: row;
    padding-top: 4rem;
    justify-content: center;
  }
  .last-row {
    display: flex;
    text-align:right;
  }
  .ima {
    width: 50px;
    height: 50px;
    margin: 0rem 4rem;
  }

  @media screen and (max-width: 1440px) {
    .slider {
      margin: inherit;
    }
    .card-group {
      width:100% !important;
    }
    .card {
      margin:1rem;
    }
    .roowcaru {
      width:auto;
    }
    .ima {
      margin: 1rem 1rem;
    }
    .carousel {
      width: 100vw !important;
    }
}
@media screen and (max-width: 850px) {
    .card{
      margin:1.5rem !important;
    }
    .card-text {
      line-height: 20px;
    }
    .card-group {
      flex-direction: column !important;
      align-items: center !important;
    }
    .titleeco {
      font-size: 30px;
    }
    .carousel-indicators{
      margin-left: 40% !important;
    }
    .ima{
      width: 30px;
    height: 30px;
    }
}
.gpt3__navbar {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 100vw;
    width: 100vw;
    background-color: var(--white);
    padding: 0rem 3rem;
}

.gpt3__navbar-links {
    max-width: 95vw;
    display: flex;
    justify-content: space-around;
    /* align-items: flex-start; */
}

.gpt3__navbar-links_logo {
    margin-right: 2rem;
    padding-top: 0rem;
}

.gpt3__navbar-links_logo img {
    width: 480px;
    height: 210px;
}

.gpt3__navbar-links_container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 60%;
}

.orangeflag {
    padding: 2.5rem 1rem;
    /* border-radius: 0 0 3rem 3rem; */
    /* margin: 0 1rem; */
    /* cursor: pointer; */
    
    text-align: center;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.orangeflag a {
    text-decoration: none;
}

.orangeflag h2{
    line-height:1.5rem;
    color: var(--purple);
    font-family: var(--font-family);
    font-weight: 800;
}
.orangeflag h3{
    line-height:1.5rem;
    color: var(--purple);
    font-family: var(--font-family);
    font-weight: 800;
    font-size:1rem;
}


.langdiv{
    border-radius: 30px;
    width: 40px;
    height: 25px;
    margin: 5px;
    cursor: pointer;
}
.langdiv img{
    width: 100%;
    height: 100%;
}

.langcontainer {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    padding-top: 2rem;
}

.gpt3__navbar-links_container p,
.gpt3__navbar-sign p,
.gpt3__navbar-menu_container p {
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;

    margin: 0 1rem;
    cursor: pointer;
}

.gpt3__navbar-sign button,
.gpt3__navbar-menu_container button {
    padding: 0.5rem 1rem;
    color: #fff;
    background: #FF4820;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
}

.gpt3__navbar-menu {
    margin-left: 1rem;

    display: none;
    position: relative;
}

.gpt3__navbar-menu svg {
    cursor: pointer;
}

.gpt3__navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    
    text-align: end;
    background: var(--color-footer);
    padding: 2rem;
    position: absolute;
    right: 0;
    top: 40px;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0,0, 0,0.2);
}

.gpt3__navbar-menu_container p {
    margin: 1rem 0;
}

.gpt3__navbar-menu_container-links-sign {
    display: none;
}
@media screen and (max-width: 1400px) {
    .gpt3__navbar {
        padding: 0 2rem;
    }
    .gpt3__navbar-links_logo img{
        width: 320px;
        height:140px;
    }
    .gpt3__navbar-links_container {
        width: 90%;
    }
    .orangeflag h3 {
        font-size: 0.8rem;
    }
}

@media screen and (min-width: 850px) {
    .gpt3__navbar-links_container{
        margin-left:5rem;
    }
}

@media screen and (max-width: 1050px) {
    .gpt3__navbar-links_container {
        /* display: none; */
    }

    .gpt3__navbar {
       padding: 0;
    }
    .gpt3__navbar-menu {
        display: flex;
    }
    .gpt3__navbar-links_logo img{
        width: 200px;
        height:100px;
    }
    .gpt3__navbar-links_logo{
        margin-right: 0;
        margin-left:1rem;
    }
    .gpt3__navbar-links_container{
        width: auto;
    }

}

@media screen and (min-width: 851px) and  (max-width: 1390px){
    .langcontainer {
        padding-right: 3rem;
    } 
}

@media screen and (max-width: 850px) {
    .gpt3__navbar-links_container {
        display: flex;
        flex-direction: column;

    }
    .orangeflag{
        padding:1rem;
    }
    .gpt3__navbar {
        display: flex;
        flex-direction: column;
        min-width:100vw;
        align-items:center;
    }
    .gpt3__navbar-links {
        display: flex;
        flex-direction: column;
        justify-content:center;
        align-items:center;
    }
    .gpt3__navbar-links_container {
        align-items: center;
    }

    .langcontainer {
        flex-direction: row;
        padding-top: 0;
        padding-bottom: 2rem;
    }
}

@media screen and (min-width: 701px) {
    .gpt3__navbar-links_logo img{
        padding-left: 2rem;
        padding-top: 1rem;
        object-fit: contain;
    }
}

@media screen and (max-width: 700px) {
    .gpt3__navbar {
        padding: 2rem 4rem;
    }
    .gpt3__navbar-links_logo img {
        width: 280px;
        height: 140px;
    }
    .langcontainer {
        /* padding-right: 3rem; */
    }
    

}
@media screen and (min-width: 768px) and (max-width: 1040px) {
    .langcontainer {
        /* padding-right: 5rem; */
    }
}


@media screen and (max-width: 550px) {
    .gpt3__navbar {
        padding: 2rem;
        align-items: center;
        display: flex;
    }

    .gpt3__navbar-sign {
        display: none;
    }

    .gpt3__navbar-menu_container {
        top: 20px;
    }

    .gpt3__navbar-menu_container-links-sign {
        display: block;
    }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-decoration: none;
}

body {
  background: var(--color-bg);
  overflow-x: hidden;
  overflow-y: auto;
}

a {
  color: unset;
  text-decoration: none;
}

.bg {
  width: 100vw;
  /* height: 80vh; */
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.gradient__text {
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section__padding {
  padding: 4rem 6rem;
}

.section__margin {
  margin: 4rem 6rem;
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@media screen and (min-width: 1024px) {
  .bg {
    height: 80vh;
  }
}
@media screen and (max-width: 1024px) {
  .bg {
    height: 600px;
  }
}
@media screen and (max-width: 700px) {
  .section__padding {
    padding: 4rem;
  }

  .section__margin {
    margin: 4rem;
  }
}

@media screen and (max-width: 550px) {
  .section__padding {
    padding: 4rem 2rem;
  }

  .section__margin {
    margin: 4rem 2rem;
  }  
}
:root {
  --font-family: 'Manrope', sans-serif;
  --font-base: 'Manrope', sans-serif;

  --gradient-text: linear-gradient(89.97deg, #AE67FA 1.84%, #F49867 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%);
  
  --color-bg: #040C18;
  --color-footer : #031B34;
  --color-blog: #042c54;
  --color-text: #81AFDD;
  --color-subtext: #FF8A71;

  --purple: #8069EE;
  --orange: #FF9E63;
  --pink: #FF64B4;
  --yellow: #FFD94A;
  --white-color:#ffffff;
  --white:#ffffff;
}

